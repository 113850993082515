import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

type StaticSEOProps = {
  titleData?: string;
  descriptionData?: string;
  OgImageData?: 'FirstViewSM' | string;
  top?: boolean;
};

const Meta: React.VFC<StaticSEOProps> = ({
  titleData,
  descriptionData,
  OgImageData,
  top,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
      images: allFile {
        nodes {
          relativePath
          name
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  const title = titleData || defaults.title;
  const description = descriptionData || defaults.description;
  const image = OgImageData
    ? data.images.nodes.find((n) => n.relativePath.includes(OgImageData))
    : false;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={top ? `website` : `article`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* fixMe: fix path after deploy */}
      {image && (
        <meta
          property="og:image"
          content="https://takashi-fujikawa.jp/static/1ca97d1a08280a175d6d1f3eafe80efa/3670a/FirstViewSM.webp"
        />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="tiwitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* fixMe: fix path after deploy */}
      {image && (
        <meta
          name="twitter:image"
          content="https://takashi-fujikawa.jp/static/1ca97d1a08280a175d6d1f3eafe80efa/3670a/FirstViewSM.webp"
        />
      )}
      {/* fixMe: add google key after deploy */}
      {top && (
        <meta
          name="google-site-verification"
          content="Co3lI7TJszWsxxNgVGRsak6nVhV-HBXVv9wo_tHaHfM"
        />
      )}
    </Helmet>
  );
};

export default Meta;
